import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import * as echarts from 'echarts';
import { xueQingStatDownload, xueQingStatInfo, xueQingStatUpdateStandard } from "@/api/api";
export default {
  name: "xqStat",
  data() {
    return {
      id: '',
      class_id: '',
      type: 'table',
      // table/bar
      classes: [],
      subjects: [],
      subjectAvgStat: [],
      show_standard_dialog: false,
      standardModel: {
        name: '',
        subjects: {}
      },
      standardRules: {
        name: [{
          required: true,
          message: '请输入标准名称',
          trigger: 'blur'
        }]
      },
      currentClass: null,
      show_class_info_dialog: false
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.class_id = this.$route.query.class_id || '';
  },
  mounted() {
    this.initData();
  },
  methods: {
    switchTo(type) {
      this.type = type;
      this.tryDrawBar();
    },
    initData() {
      let that = this;
      xueQingStatInfo({
        active_id: that.id,
        class_id: that.class_id
      }).then(res => {
        that.classes = res.data.classes;
        that.subjects = res.data.subjects;
        that.subjectAvgStat = res.data.subjectAvgStat;
        let standardStat = that.subjectAvgStat.find(item => {
          return item.type === 'standard';
        });
        let standardModel = {
          name: standardStat.class_name,
          subjects: {}
        };
        for (let i in that.subjects) {
          let key = `subject_${that.subjects[i].id}_avg`;
          standardModel.subjects[key] = standardStat[key];
        }
        that.standardModel = standardModel;
        that.$nextTick(() => {
          that.tryDrawBar();
        });
      });
    },
    tryDrawBar() {
      if (this.type === 'bar') {
        setTimeout(() => {
          this.drawBar();
        }, 100);
      }
    },
    drawBar() {
      let that = this;
      let schoolStat = that.subjectAvgStat.find(item => {
        return item.type === 'school';
      });
      let standardStat = that.subjectAvgStat.find(item => {
        return item.type === 'standard';
      });
      let xqStatCharts = {};
      for (let i in that.classes) {
        let dom_id = 'subject-avg-bar-' + that.classes[i].id;
        let dom = document.getElementById(dom_id);
        xqStatCharts[dom_id] = echarts.init(dom);
        let source = [['type', '科目均分', '年级均分', that.standardModel.name]];
        let classStat = that.subjectAvgStat.find(item => {
          return item.class_id == that.classes[i].id;
        });
        for (let i in that.subjects) {
          let subject_id = that.subjects[i].id;
          let avg_key = 'subject_' + subject_id + '_avg';
          source.push([that.subjects[i].name, classStat[avg_key], schoolStat[avg_key], standardStat[avg_key]]);
        }
        let option = {
          // title: {
          //   left: 0,
          //   top: 0,
          //   text: that.classes[i].name,
          //   textStyle: {
          //     color: '#0D6CE4',
          //     fontSize: '16px',
          //     height: '28px',
          //   },
          // },
          legend: {
            top: 0,
            right: 0
          },
          grid: {
            left: 30,
            top: 60,
            right: 0,
            bottom: 30
          },
          tooltip: {},
          dataset: {
            source: source
          },
          xAxis: {
            type: 'category'
          },
          yAxis: {},
          series: [{
            type: 'bar'
          }, {
            type: 'bar'
          }, {
            type: 'bar'
          }]
        };
        xqStatCharts[dom_id].setOption(option);
      }
    },
    subjectAvgStatRowClassName({
      row
    }) {
      return 'subject-avg-row-' + row.type;
    },
    subjectAvgStatCellClassName({
      row,
      columnIndex
    }) {
      if (columnIndex === 0) {
        return 'subject-avg-cell-first-' + row.type;
      }
    },
    subjectAvgStatCellClick(row, column) {
      if (row.type === 'standard' && column.colSpan === 1) {
        this.show_standard_dialog = true;
      }
    },
    standardSubmit(ref) {
      let that = this;
      that.$refs[ref].validate(valid => {
        if (!valid) return;
        let submitData = {
          active_id: that.id,
          line_score_name: that.standardModel.name,
          exam: []
        };
        for (let i in that.subjects) {
          let key = `subject_${that.subjects[i].id}_avg`;
          submitData.exam.push({
            exam_id: that.subjects[i].exam_id,
            line_score: that.standardModel.subjects[key]
          });
        }
        xueQingStatUpdateStandard(submitData).then(() => {
          that.$message.success('提交成功');
          that.show_standard_dialog = false;
          that.initData();
        });
      });
    },
    subjectClassAvgStat(class_id) {
      return this.subjectAvgStat.filter(item => {
        return item.type !== 'class' || item.class_id === class_id;
      });
    },
    showClassInfo(item) {
      let that = this;
      that.currentClass = item;
      that.show_class_info_dialog = true;
    },
    handleExport() {
      let that = this;
      xueQingStatDownload({
        active_id: that.id,
        class_id: that.class_id
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        });
        const link = document.createElement("a");
        link.download = '学情分析.xls'; //下载名字
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link); // 将a标签追加到文档对象中
        link.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      });
    },
    TabChange(key) {
      if (key === 'board') {
        this.$root.useRouter.push(`/xueqing/xqBoard/${this.id}?class_id=${this.class_id}`);
      }
      if (key === 'question') {
        this.$root.useRouter.push(`/xueqing/xqQuestions/${this.id}?class_id=${this.class_id}`);
      }
    }
  }
};